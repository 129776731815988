import { z } from 'zod';

const envSchema = z.object({
  LOMA_URL: z.string(),
  NOFLAKE_URL: z.string(),
  RETURNS_PORTAL_URL_TEMPLATE: z.string(),
  TITAN_URL: z.string(),
  FULLSTORY_ORG_ID: z.string(),
  FULLSTORY_INCLUDE_PERCENTAGE: z.string(),
  NODE_ENV: z.string().optional(),
});

export default envSchema.parse(process.env);
