import React from 'react';
import z from 'zod';
import { CustomerPortal } from '@narvar/nth-kit-claims';
import env from '../../env';
import { parseParams } from '../../utils/params';
import { init as initFS } from '../../utils/fullstory';

const { LOMA_URL, NOFLAKE_URL, RETURNS_PORTAL_URL_TEMPLATE, TITAN_URL } = env;

const hashParamsSchema = z.object({
  email: z.string(),
});

function CustomerPortalWrapper() {
  const params = parseParams(window.location.href);

  // email is passed in as part of the url hash
  // e.g. /{retailer}/assist#email={email}
  // This is done to prevent the email from being logged in server logs
  let email: string | undefined;
  if (window.location.hash) {
    const hashParams = window.location.hash
      .substring(1)
      .split('&')
      .reduce((acc, curr) => {
        const [key, value] = curr.split('=');
        return {
          ...acc,
          [key]: value,
        };
      }, {});
    const hashParamsEvaluated = hashParamsSchema.safeParse(hashParams);
    if (hashParamsEvaluated.success) {
      email = hashParamsEvaluated.data.email;
    }
    window.history.replaceState(
      '',
      '',
      window.location.pathname + window.location.search,
    );
  }

  // When routed to by nginx, the path to the file will be /{retailer}/assist
  const [, pathRetailerMoniker] =
    window.location.pathname.match(/^\/(.*)\/assist$/) || [];

  const retailerMoniker = pathRetailerMoniker ?? params.retailerMoniker;

  if (!retailerMoniker) {
    return <h1>No retailer query parameter provided</h1>;
  }

  initFS();

  return (
    <CustomerPortal
      params={{
        ...params,
        retailerMoniker,
        email,
      }}
      config={{
        LOMA_URL,
        NOFLAKE_URL,
        RETURNS_PORTAL_URL_TEMPLATE,
        titan: {
          sourceWriteKey: retailerMoniker,
          dataPlaneUrl: TITAN_URL,
        },
        handleError: (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        },
        routerType: 'Hash',
      }}
    />
  );
}

export default CustomerPortalWrapper;
