// Supported formats: xx_YY, xx-YY
// Return result: [xx, YY]

// Validation for language/country is placed inside nth-kit-claims.

const parseLocale = (
  locale?: string,
): [string | undefined, string | undefined] => {
  if (!locale) return [undefined, undefined];
  const splitted = locale.replace('-', '_').split('_');
  return [splitted[0], splitted[1]];
};

export default parseLocale;
