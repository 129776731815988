import parseLocale from './locale';

interface Params {
  cache?: boolean;
  retailerMoniker?: string;
  orderNumber?: string;
  source?: string;
  localeLanguage?: string;
  localeCountry?: string;
  devMode?: string;
  supportPlatform?: string;
  claimType?: string;
}

function parseParams(urlString: string): Params {
  const url = new URL(urlString);

  // Otherwise we'll fall back to looking at a query parameter
  const params = new URLSearchParams(url.search);

  const retailerMoniker = params.get('retailer') || undefined;
  const orderNumber = params.get('order_number') || undefined;
  const source = params.get('source') || undefined;
  const locale = params.get('locale') || undefined;
  const devMode = params.get('dev_mode') || undefined;
  const supportPlatform = params.get('support_platform') || undefined;
  const claimType = params.get('claim_type') || undefined;
  const cache =
    typeof params.get('cache') === 'string'
      ? params.get('cache') !== 'false'
      : undefined;
  const [localeLanguage, localeCountry] = parseLocale(locale);

  return {
    cache,
    retailerMoniker,
    orderNumber,
    source,
    localeLanguage,
    localeCountry,
    devMode,
    supportPlatform,
    claimType,
  };
}

export { parseParams };
