import * as FS from '@fullstory/browser';
import env from '../env';

const { NODE_ENV, FULLSTORY_INCLUDE_PERCENTAGE, FULLSTORY_ORG_ID } = env;

const DEBUG = NODE_ENV !== 'production';

function init(): void {
  // Initialize FullStory as a part of the initial render. This allows us to start initializing FS before the actual Claims
  // app renders and it gives us access to the parsed params used in case we need to make use of any of these in our calls to FS.
  if (
    !FS.isInitialized() &&
    !DEBUG &&
    Math.random() * 100 < parseFloat(FULLSTORY_INCLUDE_PERCENTAGE)
  ) {
    FS.init({ orgId: FULLSTORY_ORG_ID });
  }
}

export { init };
